import React from "react";
import { graphql } from "gatsby";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { Header } from "../components/Acf/Header";
import RelatedPosts from "../components/Related/RelatedPosts";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import { ContactBlock } from "../components/Acf/ContactBlock";

export const BlogPostTemplate = ({ content, image, categories, title }) => {
  return (
    <section className="single-post layout">
      {/* {image && (
        <div className="featured-image__wrapper">
          <ImagePass src={image} />
        </div>
      )} */}
      <div className="single-post__wrapper">
        <div className="content">
          {categories && categories[0] && (
            <span className="eyebrow">{categories[0].name}</span>
          )}
          <h2>{decodeEntities(title)}</h2>
          <RenderShortcode content={content} />
        </div>
      </div>
    </section>
  );
};

const BlogPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, site, siteSettings, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    categories,
    date,
  } = post;
  const { blogSlug } = wordpressWpSettings;
  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={
          featured_media &&
          featured_media.source_url &&
          featured_media.source_url
        }
        location={location}
      />
      <Header template="blog" />
      <BlogPostTemplate
        content={content}
        image={featured_media}
        categories={categories}
        title={title}
        blogSlug={blogSlug}
      />
      <RelatedPosts />
      <ContactBlock />
    </>
  );
};

export default Previewable(BlogPost, "post");

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      excerpt
      wordpress_id
      date
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1100, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
        path
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
